<script>
  import { navigate } from 'svelte-routing';

  let username = '';
  let password = '';

async function login() {
    const url = 'https://api-portal.clockworkdelivery.com/v1/users/login';
    const payload = {
      email:username,
      password
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonResponse = await response.json();
      console.log({jsonResponse})
      if (jsonResponse?.data?.fs_token?.length && jsonResponse?.data?.user?._id?.length && jsonResponse?.data?.user?.email?.length) {
        localStorage.setItem('token', jsonResponse?.data?.fs_token); // Store the token securely
        localStorage.setItem('user', JSON.stringify(jsonResponse?.data?.user)); // Store the token securely
        navigate('/home', {replace:true}); // Navigate to home or another protected route
      } else {
        alert("Authentication failed")
      }
    } catch (error) {
        console.error('Login failed:', error);
        alert("Authentication failed")
    }
  }
</script>

<style>
  .container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  h1 {
    font-size: 24px;
    color: #333;
  }
  p {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }
  input, button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
  }
  button {
    background-color: #0056b3;
    color: white;
    border: none;
    cursor: pointer;
  }
  button:hover {
    background-color: #004494;
  }
</style>

<div class="container">
  <h1>Personal Data Removal Request Form</h1>
  <p>For privacy and data protection reasons, such as pursuant to the EU General Data Protection Regulation, you may have the right to ask for certain personal data relating to you to be removed from Clockwork Logistics Systems, Inc systems.</p>
  <p>This form is for requesting the removal of specific results for queries that include your name from Clockwork Logistics Systems, Inc, including usage from our "Portal" website, "ComCon" website, Apple mobile apps, Android mobile apps, or any other Clockwork branded systems.</p>
  <p>If you want to request a removal of personal data from another Clockwork product, please submit a request through that product's form, which you can reach at our Removing Content From Clockwork page. For example, if you want to request removal of personal data from Blogger, please submit a request on the relevant Blogger form.</p>
  <p>When you make your request, we will balance your privacy and data protection rights with the public interest in having access to the information, as well as the right of others to distribute the information — for example, we may decline to remove certain information about financial scams, professional malpractice, criminal convictions, or public conduct of government officials. Find more information in this help center article.</p>

    <br/>
    <p>To confirm who you are, please login to your account</p>

  <form on:submit|preventDefault={login}>
    <input type="text" bind:value={username} placeholder="Username">
    <input type="password" bind:value={password} placeholder="Password">
    <button type="submit">Login</button>
  </form>
</div>
