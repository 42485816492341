<script>
  // Optionally, add navigation or other logic
</script>

<style>
  .goodbye {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
</style>

<div class="goodbye">
  <h1>Goodbye!</h1>
  <p>Request submitted, we will let you know as soon it has been completed.</p>
  <!-- Optionally add a link or button to navigate away -->
</div>
