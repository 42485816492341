<script>
  import { navigate } from 'svelte-routing';
  async function forgetMe() {
    const confirmation = confirm('Are you sure you want to be forgotten?');
    if (confirmation) {
      const payload = {
        captchaResult: true,
        browserTstamp: new Date().toISOString(),
        ip: '192.168.3.xxx' // Simulated IP for example
      };

        try {
            const token = localStorage.getItem('token'); // Store the token securely

            const response = await fetch('https://api-portal.clockworkdelivery.com/forgetme4ever', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                body: JSON.stringify(payload)
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonResponse = await response.json();
            console.log({jsonResponse})
            if (jsonResponse?.data?.ok) {
                navigate('/goodbye'); // Navigate to Goodbye page on success
            } else {
                alert('Failed to submit request');
            throw new Error('Failed to submit request');
            }
            // alert('Request submitted, we will let you know as soon it has been completed');
            // navigate('/');
        } catch(error) {
            console.error('Error:', error);
            alert('Failed to submit request');
        }
    }
  }
</script>

<style>
  .container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  h1 {
    font-size: 24px;
    color: #333;
  }
  h2 {
    font-size: 18px;
    color: #333;
  }
  p {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }
  button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
  }
  button {
    background-color: #0056b3;
    color: white;
    border: none;
    cursor: pointer;
  }
  button:hover {
    background-color: #004494;
  }
</style>

<div class="container">
  <h1>Personal Data Removal Request Form</h1>
  <p>For privacy and data protection reasons, such as pursuant to the EU General Data Protection Regulation, you may have the right to ask for certain personal data relating to you to be removed from Clockwork Logistics Systems, Inc systems.</p>
  <p>This form is for requesting the removal of specific results for queries that include your name from Clockwork Logistics Systems, Inc, including usage from our "Portal" website, "ComCon" website, Apple mobile apps, Android mobile apps, or any other Clockwork branded systems.</p>
  <p>If you want to request a removal of personal data from another Clockwork product, please submit a request through that product's form, which you can reach at our Removing Content From Clockwork page. For example, if you want to request removal of personal data from Blogger, please submit a request on the relevant Blogger form.</p>
  <p>When you make your request, we will balance your privacy and data protection rights with the public interest in having access to the information, as well as the right of others to distribute the information — for example, we may decline to remove certain information about financial scams, professional malpractice, criminal convictions, or public conduct of government officials. Find more information in this help center article.</p>

  <h2>Sworn statements</h2>

    <p>Please read the following statements, and check the boxes to confirm that you have read and acknowledge them.</p>

    <p>I have read and acknowledge the explanation of the processing of the personal information that I am submitting, as outlined below:</p>

    <p>Clockwork Logistics Systems, Inc will use the personal information that you supply on this form (including your email address and any ID information) and any personal information you may submit in further correspondence for the purposes of processing your request and meeting our legal obligations. We may share details of your request with data protection authorities, but only when they require these details to investigate or review a decision that we have made. That will normally be because you have chosen to contact your national DPA about our decision. Where URL(s) have been removed from our Search results as a result of your request, we may let the relevant webmaster(s) know. We will not provide your personal data.</p>

<p>We would miss you if you left us!</p>

<button on:click={forgetMe}>Forget Me</button>
</div>