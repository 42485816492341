<script>
  import { Router, Route, navigate } from 'svelte-routing';
  import Login from './Login.svelte';
  import Home from './Home.svelte';
  import Goodbye from './Goodbye.svelte';

  // This function runs before entering the /home route
  function requireAuth() {
    if (!localStorage.getItem('loggedIn')) {
		console.log("Unauthorized")
      navigate('/', { replace: true });
    }
  }
</script>

<Router>
  <Route path="/" component={Login} />
  <Route
    path="/home"
    component={Home}
    onEnter={requireAuth}
  />
  <Route path="/goodbye" component={Goodbye} />
</Router>
